import React from "react";

import { ReactComponent as Phone } from "../img/noun_Phone_52971_kontakt.svg";
import { ReactComponent as Mail } from "../img/noun_Letter_1009550_kontakt.svg";
import { ReactComponent as Pin } from "../img/noun_map pin_484145_kontakt.svg";
import { ReactComponent as Fax } from "../img/noun_fax_3592982_kontakt.svg";

import { remark } from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

import "../styles/contact.css";

const Contact = ({ contact, herosection }) => {
  const telephoneLink =
    "tel:+49" + herosection.telephone.replace(/\D/g, "").slice(1);
  const descriptionChunkOne = contact.addressSubtitle.split("Dres.")[0];
  const descriptionChunkTwo =
    "Dres. " + contact.addressSubtitle.split("Dres.")[1];

  contact.approach.attributes.map(
    (el) =>
    (el.description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(el.description)
      .toString())
  );

  return (
    <>
      <div
        id="kontakt"
        class="px-20p sm:px-10 py-50p sm:py-80p text-white relative bg-backgroundBlue"
      >
        <div>
          <div class="max-w-900p mx-auto">
            <div class="w-max">
              <h2 class="text-white font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
                {contact.title}
              </h2>
              <div class="h-[0.175rem] bg-lightblue mb-35p sm:mb-60p" />
            </div>
            <div
              id="termin-vereinbaren"
              class="flex flex-col lg:flex-row justify-between mb-35p sm:mb-60p"
            >
              <div class="mb-35p lg:mb-0 lg:w-1/2 max-w-md">
                <h4 class="mb-25p sm:mb-20p text-12121 sm:text-15152 font-medium">
                  {contact.openingHours.title}
                </h4>
                <div>
                  <table class="text-09697 sm:text-10909 hidden md:table">
                    {contact.openingHours.days.map((item) => (
                      <tr class="p-0 leading-7">
                        <td class="p-0 pr-2">{item.day}</td>

                        {item.times[0].time !== "geschlossen" ? (
                          <React.Fragment>
                            <td class="p-0 pr-2">{item.times[0].time}</td>
                            {item.times[1] && (
                              <React.Fragment>
                                <td class="p-0 pr-2">&</td>
                                <td class="p-0">{item.times[1].time}</td>
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <td class="p-0 pr-2"></td>
                            <td class="p-0 pr-2"></td>
                            {item.times[1] && (
                              <td class="p-0">{item.times[1].time}</td>
                            )}
                          </React.Fragment>
                        )}
                      </tr>
                    ))}
                  </table>
                  <div class="text-09697 sm:text-10909 md:hidden">
                    {contact.openingHours.days.map((item) => (
                      <div class="mb-2">
                        <p>{item.day}</p>
                        <p>
                          {item.times[0].time}
                          {item.times[1] && ` & ${item.times[1].time}`}
                        </p>
                      </div>
                    ))}
                  </div>
                  <a
                    class="block sm:hidden text-left mb-25p sm:mb-40p text-09697 sm:text-10909"
                    href={telephoneLink}
                  >
                    {contact.openingHours.description}
                  </a>
                  <div class="hidden sm:block text-left mb-25p sm:mb-40p text-09697 sm:text-10909">
                    {contact.openingHours.description}
                  </div>
                </div>
                {contact.earlyHours.times &&
                  contact.earlyHours.times.length !== 0 &&
                  contact.earlyHours.times[0] !== "" && (
                    <div class="space-y-2 md:space-y-1">
                      <h5 class="text-09697 sm:text-10909 font-medium mb-2 md:mb-0">
                        {contact.earlyHours.title}
                      </h5>
                      {contact.earlyHours.times.map((el) => (
                        <p class="text-09697 sm:text-10909 mb-2 md:mb-0">
                          {el}
                        </p>
                      ))}
                    </div>
                  )}
              </div>
              <div class="text-left lg:w-1/2 lg:pl-10">
                <h4 class="mb-25p sm:mb-20p text-12121 sm:text-15152 font-medium">
                  {contact.approach.title}
                </h4>
                <p class="mb-25p sm:mb-20p text-09697 sm:text-10909 font-medium">
                  {descriptionChunkOne} <br /> {descriptionChunkTwo}
                </p>
                <div class="space-y-[1.07rem]">
                  <a
                    href={contact.googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="flex items-center"
                  >
                    <Pin class="mr-3 bg-white rounded-full border-white h-30p w-30p min-w-[1.875rem]" />
                    <div>
                      <div class="text-09697 sm:text-10909">
                        {herosection.address}
                      </div>
                    </div>
                  </a>
                  <a
                    href={`mailto:${herosection.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="flex items-center"
                  >
                    <Mail class="mr-3 bg-white rounded-full border-white h-30p w-30p min-w-[1.875rem]" />
                    <div>
                      <div class="text-09697 sm:text-10909">
                        {herosection.email}
                      </div>
                    </div>
                  </a>
                  <a href={telephoneLink} class="flex items-center">
                    <Phone class="mr-3 bg-white rounded-full border-white h-30p w-30p min-w-[1.875rem]" />
                    <div>
                      <div class="text-09697 sm:text-10909">
                        {herosection.telephone}
                      </div>
                    </div>
                  </a>
                  <div class="flex items-center">
                    <Fax class="mr-3 bg-white rounded-full border-white h-30p w-30p min-w-[1.875rem]" />
                    <div>
                      <div class="text-09697 sm:text-10909">
                        {herosection.fax}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full max-w-900p mx-auto bg-transparent mb-35p sm:mb-60p">
          <h4 id="termin" class="mb-25p sm:mb-20p text-12121 sm:text-15152 font-medium">
            Ihre Terminanfrage
          </h4>
          <iframe src="https://gastroenterologen-nuernberg-iframe.vercel.app/" class="h-contact rounded-3xl w-full" />
        </div>
        <div class="max-w-900p mx-auto">
          <h4 class="mb-25p sm:mb-20p text-12121 sm:text-15152 font-medium">
            {contact.approach.title}
          </h4>
          <div class="flex flex-col lg:flex-row items-center justify-between space-y-25p lg:space-y-0 lg:space-x-20p text-09697 sm:text-10909 leading-6">
            {contact.approach.attributes.map((el) => (
              <div class="bg-white p-20p w-72 h-72 rounded-3xl">
                <div class="w-50p h-50p">
                  <img
                    src={el.imageObject.image.publicURL}
                    alt={el.imageObject.alt}
                  />
                </div>
                <div
                  class="mt-20p markdownAttributes"
                  dangerouslySetInnerHTML={{ __html: el.description }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class="px-20p sm:px-10">
        <div class="my-50p sm:my-80p max-w-900p mx-auto">
          <div class="aspect-w-3 aspect-h-2 mx-auto">
            <iframe
              title="Google Maps"
              src={contact.googleMapsEmbedLink}
              width="600"
              height="450"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
